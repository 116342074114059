import { ReactComponent as HomeIcon } from "assets/icons/home-2.svg"
import { ReactComponent as InfoIcon } from "assets/icons/info-circle.svg"
import { ReactComponent as SearchIcon } from "assets/icons/search-normal-1.svg"

import "./MobileBar.scss"

export const MobileBar = () => {
  return (
    <nav className="MobileBar">
      <HomeIcon />
      <SearchIcon />
      <InfoIcon />
    </nav>
  )
}