import { createPortal } from "react-dom"
import { PopupProps } from "./Popup.type"

import "./Popup.scss"

export const Popup = ({ children, closeHandler, open }: PopupProps) => {
  if (!open) return null;
  return (
    <>
      {createPortal(
        <>
          <div className="Popup-Overlay" onClick={(e) => { e.preventDefault(); e.stopPropagation(); closeHandler() }} />
          <div className="Popup-Content">{children}</div>
        </>,
        document.getElementById('popups') as Element
      )}
    </>
  );
}