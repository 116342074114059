import { Header } from "components/organisms/Header"
import "./Main.scss"
import { MobileBar } from "components/organisms/MobileBar"
import { VehicleCard } from "components/molecules/VehicleCard"
import { useContext, useState } from "react"
import { wsContext } from "contexts/ws.context"
import { Vehicle } from "types/Vehicle.type"
import { ActionButton } from "components/atoms/ActionButton"
import { Filters } from "components/molecules/Filters"
import { isActive, isDepot } from "utils/filters"
import { filtersContext } from "contexts/filters.context"

export const Main = () => {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [openFiltersPopup, setOpenFiltersPopup] = useState(false);

  const filters = useContext(filtersContext);
  const socket = useContext(wsContext);

  socket?.on('veh_list', ({ data }) => setVehicles(data))

  const filteredVehicles = vehicles
    .filter(vehicle => {
      if (vehicle.veh_ts && filters?.filters) {
        const vehicleIsActive = isActive(vehicle.veh_ts)

        return filters.filters?.isActive ? vehicleIsActive : true
      }

      return false
    })
    .filter(vehicle => {
      if (vehicle && filters?.filters) {
        return isDepot(vehicle.depot_short_name, filters.filters?.fromDepot)
      }

      return true
    });

  const handleCloseFilters = () => {
    setOpenFiltersPopup(false)
  }

  const handleOpenFilters = () => {
    setOpenFiltersPopup(true)
  }

  return (
    <main className="Main">
      <Header />
      <ul className="Main-List">
        {filteredVehicles.map(
          vehicle => (
            <li className="Main-ListItem" key={vehicle.veh_no}>
              <VehicleCard vehicle={vehicle} />
            </li>
          )
        )}
      </ul>
      <ActionButton onClick={handleOpenFilters} />
      <MobileBar />
      <Filters open={openFiltersPopup} closeHandler={handleCloseFilters} />
    </main>
  )
}