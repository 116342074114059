import { Main } from "pages/Main"
import { useEffect, useState } from "react";
import "normalize.css"
import { connect } from "utils/sockets";
import { wsContext } from "contexts/ws.context";
import { filtersContext } from "contexts/filters.context";
import type { Socket } from "socket.io-client";
import type { DefaultEventsMap } from "socket.io/dist/typed-events";
import { FiltersType } from "types/Filters.type";

const initialFilters: FiltersType = localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters') ?? "") : {
  fromDepot: ['TP', 'TT', 'TW', 'TH', 'TB'],
  isActive: true
}

export const App = () => {
  const [filters, setFilters] = useState<FiltersType>(initialFilters);
  const [socket, setSocket] = useState<Socket<DefaultEventsMap, DefaultEventsMap>>();

  useEffect(() => {
    const connection = connect();

    setSocket(connection);

    connection.on('connect_error', (error) => console.log(error))

    console.log('%cKontakt: kontakt@stweb.pl', 'color: red; font-size: larger; font-weight: bold')

    return () => {
      connection.close();
    }
  }, [])

  return (
    <wsContext.Provider value={socket}>
      <filtersContext.Provider value={{ filters, setFilters }}>
        <Main />
      </filtersContext.Provider>
    </wsContext.Provider>
  )
}