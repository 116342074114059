import dayjs from "dayjs";
import { Depots } from "types/Filters.type";

export const isActive = (time: string) => {
  const diffAfter = dayjs(time).add(5, 'minutes').isAfter(dayjs());
  const diffBefore = dayjs(time).subtract(5, 'minutes').isBefore(dayjs());

  return diffBefore && diffAfter
}

export const isDepot = (veh_depot: Depots, filteredDepots: Depots[]) => {
  return filteredDepots.includes(veh_depot)
}