import { Popup } from "components/atoms/Popup"
import { FiltersProps } from "./Filters.type"
import { Button } from "components/atoms/Button"

import "./Filters.scss"
import { useContext } from "react"
import { filtersContext } from "contexts/filters.context"
import { Depots } from "types/Filters.type"

export const Filters = ({ closeHandler, open }: FiltersProps) => {
  const filters = useContext(filtersContext);
  const setFilters = filters?.setFilters;

  const handleChangeIsActive = () => {
    if (setFilters && filters?.filters) {
      const oldFilters = filters.filters;
      const newFilters = { ...oldFilters, isActive: !oldFilters.isActive };

      localStorage.setItem('filters', JSON.stringify(newFilters))

      setFilters(newFilters);
    }
  }

  const handleChangeDepot = (depot: Depots) => () => {
    if (setFilters && filters?.filters) {
      const oldFilters = filters.filters;

      if (oldFilters?.fromDepot.includes(depot)) {
        const newFilters = { ...oldFilters, fromDepot: oldFilters.fromDepot.filter(depotFromList => depot !== depotFromList) };
        setFilters(newFilters)
        localStorage.setItem('filters', JSON.stringify(newFilters))
      } else {
        const newFilters = { ...oldFilters, fromDepot: [...oldFilters.fromDepot, depot] };
        setFilters(newFilters);
        localStorage.setItem('filters', JSON.stringify(newFilters))
      }
    }
  }

  return (
    <Popup closeHandler={closeHandler} open={open}>
      <h3 className="Filters-Title">Filtry</h3>
      {/* <h4 className="Filters-Subtitle">Nr boczny</h4>
      <div className="Filters-VehicleNumber">
        <input type="text" maxLength={5} />
      </div> */}
      <h4 className="Filters-Subtitle">Zajezdnia</h4>
      <div className="Filters-Depots">
        <label>
          <input type="checkbox" title="Bieńczyce" checked={filters?.filters?.fromDepot.indexOf('TB') !== -1} onClick={handleChangeDepot('TB')} />Bieńczyce
        </label>
        <label>
          <input type="checkbox" title="Nowa Huta" checked={filters?.filters?.fromDepot.indexOf('TH') !== -1} onClick={handleChangeDepot('TH')} />Nowa Huta
        </label>
        <label>
          <input type="checkbox" title="Płaszów" checked={filters?.filters?.fromDepot.indexOf('TP') !== -1} onClick={handleChangeDepot('TP')} />Płaszów
        </label>
        <label>
          <input type="checkbox" title="Podgórze" checked={filters?.filters?.fromDepot.indexOf('TT') !== -1} onClick={handleChangeDepot('TT')} />Podgórze
        </label>
        <label>
          <input type="checkbox" title="Wola Duchacka" checked={filters?.filters?.fromDepot.indexOf('TW') !== -1} onClick={handleChangeDepot('TW')} />Wola Duchacka
        </label>
      </div>
      <h4 className="Filters-Subtitle">Pozostałe</h4>
      <div className="Filters-Depots">
        <label>
          <input type="checkbox" title="Wola Duchacka" checked={filters?.filters?.isActive} onClick={handleChangeIsActive} />Jest aktywny
        </label>
      </div>
      <div className="Filters-Actions">
        <Button onClick={closeHandler}>Zamknij</Button>
      </div>
    </Popup>
  )
}