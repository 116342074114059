import { ReactComponent as ArrowIcon } from "assets/icons/arrow-bottom.svg"
import { ReactComponent as DirectRightIcon } from "assets/icons/direct-right.svg"
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import pl from "dayjs/locale/pl"
import type { VehicleCardProps } from "./VehicleCard.type"

import "./VehicleCard.scss"

dayjs.extend(relativeTime)

export const VehicleCard = ({ vehicle }: VehicleCardProps) => {
  const { direction, next_stop, variant, veh_no, veh_ts } = vehicle;

  return (
    <div className="VehicleCard">
      <header className="VehicleCard-Header">
        <h2 className="VehicleCard-Number">{veh_no}</h2>
        {!!veh_ts && <time className="VehicleCard-LastSeen"><EyeIcon /> {dayjs(veh_ts).locale(pl).fromNow()}</time>}
      </header>
      {(variant || direction) && <div className="VehicleCard-Direction">
        <DirectRightIcon />
        {variant} {direction}
      </div>}
      {next_stop && <div className="VehicleCard-NextStop">
        <ArrowIcon />
        {next_stop}
      </div>}
    </div>
  )
}