import { ReactComponent as FilterIcon } from "assets/icons/filter.svg"
import "./ActionButton.scss"
import { ButtonHTMLAttributes } from "react"

export const ActionButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button className="ActionButton" aria-label="Akcja" {...props}>
      <FilterIcon />
    </button>
  )
}